import Vue from 'vue';
import Router from 'vue-router';
import _ from 'lodash';
import api from './../api/activityLog.js';

Vue.use(Router);

const routerOptions = [
  {
    path: '/',
    name: 'public',
    visible: false
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    visible: false,
    meta: {
      title: 'Dashboard',
      permissions: [
        'get-dashboard'
      ],
    }
  },
  {
    path: '/home',
    name: 'home',
    visible: false,
    meta: {
      title: 'Inicio'
    }
  },
  {
    path: '/login',
    name: 'login',
    visible: false,
  },
  {
    path: '/logout',
    name: 'logout',
    visible: false,
  },
  {
    path: '/register',
    name: 'register',
    visible: false,
  },
  {
    path: '/users',
    name: 'users',
    visible: true,
    meta: {
      title: 'Usuarios / Gestión de Usuarios',
      permissions: [
        'get-users', 'store-users', 'update-users', 'delete-users',
        'get-abilities', 'store-abilities', 'update-abilities', 'delete-abilities',
        'get-roles', 'store-roles', 'update-abilities', 'delete-abilities',
      ],
    },
  },
 
]
// Rutas
const routes = _.map(routerOptions, r => {
  return {
    ...r,
    component: () => import(`../components/${r.name}/index-component.vue`)
  }
})

const router = new Router({
  // mode: 'history', 
  base: __dirname,
  routes
})

router.beforeEach((to, from, next) => {
  const pathModule = (_.split(to.meta.title, '/'))
  const module = pathModule[0];
  const subModule = pathModule[1];
  (to.name !='login') ? api.storeActivityLog({ action: 'View', entity: 'User', module: module, sub_module: !_.isUndefined(subModule)?subModule:module  }) : '';
  next()
})

export default router