// import * as firebase from 'firebase';
// import "firebase/auth";

import { isNull } from 'lodash';
import api from '../../api/authentication.js';
import router from '../../routes/index.js';


const namespaced = true

const state = {
    iaccept: false,
    isLoggedIn: false,
    showLogoutDialog: false,
    providerParameters: {
        prompt: 'select_account'
    },
    loading : false,
}

const mutations = {
    MUTATION_SET_LOGOUT(state, value) {
        state.showLogoutDialog = value;
    },
    SET_LOGINSTATUS(state, input_data) {
        state.isLoggedIn = input_data;
    },
    SET_LOADING(state, value) {
        state.loading = value;
    },
}

const actions = {
    login_server(context, data) {
        context.commit('SET_LOADING',true);
        return new Promise((resolve) => {
            api.login_server(data).then(function (res) {
                context.dispatch('notifications/add', { 'color': isNull(res.data.token) ? 'error' : 'success', 'visibility': true, 'timeout': 5000, 'text': res.data.message }, { root: true });
                context.dispatch('redirect_after_login', res.data)
                context.dispatch('configuration/setUserAuth', res.data.user, { root: true })
                // context.dispatch('warehouses/setConfiguredWarehouses', res.data.user.whs, { root: true })
                // context.dispatch('costcenter/getCostCenterUser', res.data.user.cc, { root: true })
                context.commit('SET_LOADING',false);
                resolve(res);
            }).catch(function (error) {
                context.commit('SET_LOADING',false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.response.data.data.message }, { root: true });
            })
        })
    },
    redirect_after_login(context, data) {
        if (!isNull(data.token)) {
            router.push('/home');
        }
    },
    redirect_after_logout(context, data) {
        context.dispatch('evidences/setClearData', [] , { root: true })
        context.dispatch('configuration/setUserAuth', { id: 0 }, { root: true });
        if (!isNull(data.code) || !data.code) {
            router.push('/login');
        }
    },
    check_token(context) {
        api.check_token().then(function (res) {
            if (res.data.code) {
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': res.data.message }, { root: true });
                if (router.currentRoute.name == 'login') {
                    console.log("check_token")
                    console.log(res)
                    router.push('/home');
                }
            } else {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': res.data.message }, { root: true });
                if (router.currentRoute.name != 'login') {
                    router.push('/login');
                }
            }
        }).catch(function (error) {
            console.log(error);
            if (router.currentRoute.name != 'login') {
                router.push('/login');
            }
        })
    },
    logout(context) {
        api.logout().then(function (res) {
            context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': res.data.message }, { root: true });
            context.dispatch('redirect_after_logout', res.data);
        }).catch(function () {
            context.dispatch('redirect_after_logout', { 'code': false });
        })
    }
}

const getters = {

}


export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}